import { useEffect, useState } from "react";
import { BiEdit } from "react-icons/bi";
import { Link } from "react-router-dom";
import { fetchData } from "../../../../GlobalFunctions";
import { ImSpinner } from "react-icons/im";

function AllowViewCourse({ setRefreshData }: any) {
  interface CoursesData {
    id: string;
    name: string;
  }

  const [coursesData, setCoursesData] = useState<CoursesData[] | null>(null);
  const [filteredCourses, setFilteredCourses] = useState<CoursesData[] | null>(
    null
  );
  const [coursesSearchTerm, setCoursesSearchTerm] = useState("");

  useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_ALL_COURSES}`,
      setCoursesData
    );
  }, [setRefreshData]);

  useEffect(() => {
    if (coursesData) {
      const filtered = coursesData
        .filter((course) =>
          course.name.toLowerCase().includes(coursesSearchTerm.toLowerCase())
        )
        .sort((a, b) => a.name.localeCompare(b.name));
      setFilteredCourses(filtered);
    }
  }, [coursesData, coursesSearchTerm]);

  console.log(coursesData);

  return (
    <div>
      <div>
        <div className="grid grid-cols-3 gap-5 mb-5">
          <div className=" flex flex-col  ">
            <label className="pb-2 text-[20px]">Course Name:</label>
            <input
              type="text"
              placeholder="Search by name"
              value={coursesSearchTerm}
              onChange={(e) => setCoursesSearchTerm(e.target.value)}
              className="border-[2px] text-black outline-none p-1 rounded-lg h-full"
            />
          </div>
        </div>
      </div>
      {filteredCourses && filteredCourses.length > 0 ? (
        <div className=" overflow-x-auto shadow-md sm:rounded-lg mb-20">
          <table className="w-full text-sm text-center">
            <thead className="border-b bg-gray-300">
              <tr>
                <th scope="col" className="px-6 py-3 ">
                  ID
                </th>
                <th scope="col" className="px-6 py-3 w-[60%] text-left">
                  Course Name
                </th>
                <th scope="col" className="px-6 py-3 ">
                  Course Published Management
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredCourses?.map((course, index) => (
                <tr key={index} className="bg-gray-200 border-b ">
                  <td className="px-6 py-4">{course.id}</td>
                  <td className="px-6 py-4 text-left">{course.name}</td>
                  <td className="px-6 py-4 flex flex-row gap-2  justify-center">
                    <Link
                      state={{ course_id: course.id }}
                      to={`/chapters_published_status/edit_status/${course.name}`}
                      className=" bg-[#4D30B5] hover:bg-[#1b1436] text-white p-2 rounded duration-300"
                    >
                      <BiEdit />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="flex justify-center">
          <ImSpinner size={30} className=" animate-spin" />
        </div>
      )}
    </div>
  );
}

export default AllowViewCourse;

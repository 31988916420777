import { useState, useEffect } from "react";
import { fetchData } from "../../../../GlobalFunctions";
import { ImSpinner } from "react-icons/im";
import { toast } from "react-toastify";
import api from "../../../../api";
import { MenuItem, Select } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";

import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import TickPlacementBars from "./TickPlacementBars";

type TickParamsSelectorProps = {
  tickPlacement: "end" | "start" | "middle" | "extremities";
  tickLabelPlacement: "tick" | "middle";
  setTickPlacement: React.Dispatch<
    React.SetStateAction<"end" | "start" | "middle" | "extremities">
  >;
  setTickLabelPlacement: React.Dispatch<
    React.SetStateAction<"tick" | "middle">
  >;
};

const chartSetting = {
  width: 700,
  height: 500,
};

function AdminDashboard({ setRefresh }: any) {
  interface Dashboard {
    Number_of_all_questions: string;
    Number_of_tts_questions: string;
    Number_of_normal_questions: string;
    Number_of_stt_questions: string;
    total_input_tokens: string;
    total_output_tokens: string;
    total_stt_minutes: string;
    total_tts_characters: string;
    number_of_users: string;
    input_token_cost: string;
    output_token_cost: string;
    stt_cost: string;
    total_cost: string;
    tts_cost: string;
  }

  interface SchoolData {
    id: string;
    name: string;
    max_students: string;
    current_users: string;
  }

  interface RatingsData {
    average_rating: number;
    course_id: string;
    course_name: string;
  }

  const [loading, setLoading] = useState(true);
  const user_id = localStorage.getItem("user_id") || "default_user";
  const [dashboardData, setDashboardData] = useState<Dashboard>();
  const [ratingsData, setRatingsData] = useState<RatingsData[]>();
  const [schoolsData, setSchoolsData] = useState<SchoolData[] | null>(null);
  const [selectedSchool, setSelectedSchool] = useState("all");
  const [selectedModel, setSelectedModel] = useState("all");
  const [selectedTtsModel, setSelectedTtsModel] = useState("all");
  const [selectedSttModel, setSelectedSttModel] = useState("all");
  const today = new Date();
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(today.getFullYear() - 1);

  const formatDate = (date: Date) => {
    return date.toISOString().split("T")[0];
  };

  const [fromDate, setFromDate] = useState<string>(formatDate(oneYearAgo));
  const [toDate, setToDate] = useState<string>(formatDate(today));

  useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_ALL_SCHOOLS}`,
      setSchoolsData
    );
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_ADMIN_DASHBOARD_RATINGS}`,
      setRatingsData
    );
  }, []);

  useEffect(() => {
    setLoading(true);

    async function fetchData1() {
      try {
        const formData = new FormData();

        formData.append("school_id", selectedSchool);
        formData.append("model", selectedModel);
        formData.append("tts_model", selectedTtsModel);
        formData.append("stt_model", selectedSttModel);

        if (fromDate !== undefined) {
          formData.append("from", fromDate);
        }
        if (toDate !== undefined) {
          formData.append("to", toDate);
        }

        const response = await api.post(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_ADMIN_DASHBOARD_DATA}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const data = response.data;
        setDashboardData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData1();

    setLoading(false);
  }, [
    selectedSchool,
    fromDate,
    toDate,
    selectedModel,
    selectedTtsModel,
    selectedSttModel,
  ]);

  const handleFromDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFromDate = e.target.value;
    if (toDate && new Date(newFromDate) > new Date(toDate)) {
      toast.error("From date cannot be after to date!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } else {
      setFromDate(newFromDate);
    }
  };

  const handleToDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newToDate = e.target.value;
    if (fromDate && new Date(newToDate) < new Date(fromDate)) {
      toast.error("To date cannot be before from date!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } else {
      setToDate(newToDate);
    }
  };

  function valueFormatter(value: number | null) {
    return `${value} Stars`;
  }

  // if (loading) {
  //   return (
  //     <div className="flex justify-center mt-10">
  //       <ImSpinner size={30} className=" animate-spin" />
  //     </div>
  //   );
  // }

  return (
    <section
      className="overflow-scroll h-screen"
      style={{ maxHeight: "calc(100vh - 50px)" }}
    >
      <div>
        <div className="text-[22px] font-bold">Filter:</div>
        <div className="grid sm:grid-cols-2 md:grid-cols-3 grid-cols-1 items-center content-center flex-wrap w-full gap-2">
          <div className="flex flex-row items-center gap-2">
            <label className="pb-2 text-[20px]">From:</label>
            <input
              required
              type="date"
              value={fromDate}
              onChange={handleFromDateChange}
              className="border-[2px] text-black outline-none p-1 rounded-lg"
            />
          </div>
          <div className="flex flex-row items-center gap-2">
            <label className="pb-2 text-[20px]">To:</label>
            <input
              required
              type="date"
              value={toDate}
              onChange={handleToDateChange}
              className="border-[2px] text-black outline-none p-1 rounded-lg"
            />
          </div>
          <div className=" flex flex-row  items-center gap-2">
            <label className="pb-2 text-[20px]">School:</label>
            <Select
              value={selectedSchool}
              onChange={(e) => setSelectedSchool(e.target.value)}
              className=" w-52 "
            >
              <MenuItem id="tone-div" key="all" value="all">
                All
              </MenuItem>
              {schoolsData &&
                schoolsData.map((school, index) => (
                  <MenuItem id="tone-div" key={index} value={school.id}>
                    {school.name}
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div className=" flex flex-row  items-center gap-2">
            <label className="pb-2 text-[20px]">Model:</label>
            <Select
              value={selectedModel}
              onChange={(e) => setSelectedModel(e.target.value)}
              className=" w-52 "
            >
              <MenuItem id="tone-div" key="all" value="all">
                All
              </MenuItem>
              <MenuItem key="no" value="gpt-4">
                gpt-4
              </MenuItem>
              <MenuItem key="yes" value="gpt-4o">
                gpt-4o
              </MenuItem>
              <MenuItem key="yes" value="gpt-4o-mini">
                gpt-4o-mini
              </MenuItem>
            </Select>
          </div>
          <div className=" flex flex-row  items-center gap-2">
            <label className="pb-2 text-[20px]">STT Model:</label>
            <Select
              value={selectedSttModel}
              onChange={(e) => setSelectedSttModel(e.target.value)}
              className=" w-52 "
            >
              <MenuItem id="tone-div" key="all" value="all">
                All
              </MenuItem>
              <MenuItem key="no" value="whisper">
                Whisper
              </MenuItem>
            </Select>
          </div>
          <div className=" flex flex-row  items-center gap-2">
            <label className="pb-2 text-[20px]">TTS Model:</label>
            <Select
              value={selectedTtsModel}
              onChange={(e) => setSelectedTtsModel(e.target.value)}
              className=" w-52 "
            >
              <MenuItem id="tone-div" key="all" value="all">
                All
              </MenuItem>
              <MenuItem key="no" value="TTS">
                TTS
              </MenuItem>
            </Select>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="flex justify-center mt-10">
          <ImSpinner size={30} className=" animate-spin" />
        </div>
      ) : (
        dashboardData && (
          <div className="p-2 border-t-2 mt-5 pt-5">
            <div className="grid sm:grid-cols-2 md:grid-cols-3 grid-cols-1 gap-6">
              <div className="bg-gray-100 p-4 rounded-lg shadow border-l-8 border-r-8 border-l-yellow-500 border-r-yellow-500">
                <h2 className="text-lg font-semibold">Number of Users</h2>
                <p className="text-yellow-600 font-bold text-[30px] text-center">
                  {dashboardData.number_of_users}
                </p>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg shadow border-l-8 border-r-8 border-l-red-700 border-r-red-700">
                <h2 className="text-lg font-semibold">Total Input Tokens</h2>
                <p className="text-red-600 font-bold text-[30px] text-center">
                  {dashboardData.total_input_tokens} Tokens ={" "}
                  {dashboardData.input_token_cost}$
                </p>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg shadow border-l-8 border-r-8 border-l-red-700 border-r-red-700">
                <h2 className="text-lg font-semibold">Total Output Tokens</h2>
                <p className="text-red-600 font-bold text-[30px] text-center">
                  {dashboardData.total_output_tokens} Tokens ={" "}
                  {dashboardData.output_token_cost}$
                </p>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg shadow border-l-8 border-r-8 border-l-blue-600 border-r-blue-600">
                <h2 className="text-lg font-semibold">
                  Number of All Questions
                </h2>
                <p className="text-blue-600 font-bold text-[30px] text-center">
                  {dashboardData.Number_of_all_questions} Qestions ={" "}
                  {dashboardData.total_cost}$
                </p>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg shadow border-l-8 border-r-8 border-l-orange-500 border-r-orange-500">
                <h2 className="text-lg font-semibold">Total TTS Characters</h2>
                <p className="text-orange-600 font-bold text-[30px] text-center">
                  {dashboardData.total_tts_characters} Characters ={" "}
                  {dashboardData.tts_cost}$
                </p>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg shadow border-l-8 border-r-8 border-l-orange-500 border-r-orange-500">
                <h2 className="text-lg font-semibold">Total STT Minutes</h2>
                <p className="text-orange-600 font-bold text-[30px] text-center">
                  {dashboardData.total_stt_minutes} Minutes ={" "}
                  {dashboardData.stt_cost}$
                </p>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg shadow border-l-8 border-r-8 border-l-blue-600 border-r-blue-600">
                <h2 className="text-lg font-semibold">
                  Number of Normal Questions
                </h2>
                <p className="text-blue-600 font-bold text-[30px] text-center">
                  {dashboardData.Number_of_normal_questions}
                </p>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg shadow border-l-8 border-r-8 border-l-blue-600 border-r-blue-600">
                <h2 className="text-lg font-semibold">
                  Number of TTS Questions
                </h2>
                <p className="text-blue-600 font-bold text-[30px] text-center">
                  {dashboardData.Number_of_tts_questions}
                </p>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg shadow border-l-8 border-r-8 border-l-blue-600 border-r-blue-600">
                <h2 className="text-lg font-semibold">
                  Number of STT Questions
                </h2>
                <p className="text-blue-600 font-bold text-[30px] text-center">
                  {dashboardData.Number_of_stt_questions}
                </p>
              </div>
            </div>

            <div className="mt-10 mb-10 py-10">
              <TickPlacementBars></TickPlacementBars>
            </div>
          </div>
        )
      )}
    </section>
  );
}

export default AdminDashboard;

import { AnimatePresence, motion } from "framer-motion";
import Okation from "../../Assets/OkationLogo.png";
import boxMind from "../../Assets/boxMind_wide.png";
import AI from "../../Assets/AI.jpg";
import { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { login } from "../../authSlice";
import { useNavigate } from "react-router";
import Cookies from "js-cookie";
import { BiSolidShow, BiSolidHide } from "react-icons/bi";
import { useParams } from "react-router-dom";
import api from "../api";
import { ImSpinner } from "react-icons/im";

interface LoginResponse {
  message: string;
  access_token: string;
  user: {
    user_name: string;
    user_email: string;
    user_id: string;
    roles: [string];
  };
}

function ChangePassword({ setUserID }: any) {
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [refresh, setRefresh] = useState(0);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData1() {
      try {
        const formData = new FormData();
        formData.append("token", token ?? "");
        const response = await axios.post(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_VERIFY_TOKEN}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const data = response.data;
        setLoading(false);
      } catch (error) {
        navigate("/*");
        // console.error("Error fetching data:", error);
      }
    }

    fetchData1();
  }, [refresh]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      toast.error("Passwords do not match!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      return;
    }

    const passwordPattern =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!])(?=.{8,})/;

    if (!passwordPattern.test(password)) {
      toast.error(
        "Password must contain at least 1 capital letter, 1 small letter, 1 special character, 1 number, and be at least 8 characters long.",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        }
      );
      return;
    }

    const formData = new FormData();

    formData.append("token", token ?? "");
    formData.append("new_password", password);

    try {
      let endpoint = `${process.env.REACT_APP_RESET_PASSWORD}`;
      await api.post(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // window.location.href = `/classes`;
      toast.success("Password has been changed successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });

      navigate("/signin");
    } catch (error) {
      if (error instanceof Error) {
        // You can check for specific error properties or just use a default message
        const errorMessage =
          (error as any)?.response?.data?.message ||
          error.message ||
          "An unexpected error occurred";

        toast.error(errorMessage, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      } else {
        // Fallback for when error is not an instance of Error
        toast.error("An unexpected error occurred", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }

      console.log(error);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setIsVisible(!isVisible);
  };

  if (loading) {
    return (
      <div className="flex justify-center mt-10">
        <ImSpinner size={30} className=" animate-spin" />
      </div>
    );
  }

  return (
    <section className=" lg:grid grid-flow-row-dense grid-cols-10 ">
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          viewport={{ once: false }}
          transition={{ duration: 0.7 }}
          className=" w-full col-span-3"
        >
          <form
            onSubmit={handleSubmit}
            className=" w-full flex flex-col justify-center items-center mx-auto h-screen bg-gray-200"
          >
            <div className="my-6">
              <img src={boxMind} width={220} alt="boxMind" loading="lazy" />
            </div>
            <div className=" flex flex-col gap-4 ">
              <p className=" flex justify-center text-[40px] font-bold pb-[50px] text-[#135991]">
                Reset Password
              </p>
              <div className=" pb-[50px] flex flex-col gap-5">
                <div className=" flex flex-row justify-between items-center gap-5 text-[#135991]">
                  <p className=" font-bold">New Password</p>
                  <input
                    type={isVisible ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className=" border p-2 outline-none rounded-lg"
                  />
                </div>
                <div className=" relative flex flex-row justify-between items-center gap-5 text-[#135991]">
                  <p className=" font-bold">Confirm Password</p>
                  <input
                    type={isVisible ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className=" border p-2 outline-none rounded-lg"
                  />
                  <button
                    type="button"
                    onClick={handleTogglePasswordVisibility}
                    className="outline-none text-[#135991] absolute right-2"
                  >
                    {isVisible ? <BiSolidShow /> : <BiSolidHide />}
                  </button>
                </div>
              </div>
              <button
                type="submit"
                className=" bg-[#135991] hover:bg-[#062c47] duration-300 p-4 rounded-lg text-white font-semibold "
              >
                Submit
              </button>
              <div className=" flex flex-col items-center justify-center pt-3 ">
                {/* <div className=" flex flex-row font-bold text-[12px] gap-0.5">
                  <span className=" text-[#135991]">
                    {" "}
                    Don't have an Account?
                  </span>{" "}
                  <a
                    href="/signup"
                    className=" text-blue-700 underline hover:text-blue-800 duration-300"
                  >
                    Sign Up
                  </a>
                </div> */}
                <div className="flex flex-row items-center gap-2 pt-24">
                  <span className=" text-[#135991] font-semibold">
                    Powered by
                  </span>{" "}
                  <a
                    href="https://boxmind.ai/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={Okation}
                      width={40}
                      alt="Okation"
                      className=" flex justify-center items-center"
                      loading="lazy"
                    />
                  </a>
                </div>
              </div>
            </div>
          </form>
        </motion.div>
      </AnimatePresence>
      <div className=" col-span-7 w-full lg:h-screen max-lg:hidden">
        <img src={AI} alt="AI" className="w-full h-full" loading="lazy" />
      </div>
    </section>
  );
}

export default ChangePassword;

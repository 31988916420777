import { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import api from "../../../../api";
import SelectInput from "@mui/material/Select/SelectInput";
import { MenuItem, Select } from "@mui/material";

function AddPlan({
  handlePlanClick,
  closePlan,
  setRefresh,
  setRefreshData,
}: any) {
  const [name, setName] = useState("");
  const [price, setPrice] = useState<number | null>(0);
  const [maxInputTokens, setMaxInputTokens] = useState<number | null>(0);
  const [maxOutputTokens, setMaxOutputTokens] = useState<number | null>(0);
  const [model, setModel] = useState<string | null>("gpt-4");
  const [sttModel, setSttModel] = useState<string | null>("Whisper");
  const [sttMinutes, setSttMinutes] = useState<number | null>(0);
  const [ttsModel, setTtsModel] = useState<string | null>("TTS");
  const [ttsCharacters, setTtsCharacters] = useState<number | null>(0);

  useEffect(() => {
    if (price && price < 0) {
      setPrice(0);
    }
  }, [price]);

  useEffect(() => {
    if (maxInputTokens && maxInputTokens < 0) {
      setMaxInputTokens(0);
    }
  }, [maxInputTokens]);

  useEffect(() => {
    if (maxOutputTokens && maxOutputTokens < 0) {
      setMaxOutputTokens(0);
    }
  }, [maxOutputTokens]);

  useEffect(() => {
    if (sttMinutes && sttMinutes < 0) {
      setSttMinutes(0);
    }
  }, [sttMinutes]);

  useEffect(() => {
    if (ttsCharacters && ttsCharacters < 0) {
      setTtsCharacters(0);
    }
  }, [ttsCharacters]);

  const handleUploadSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", name);
    if (model !== null) {
      formData.append("model", model);
    }
    if (sttModel !== null) {
      formData.append("stt_model", sttModel);
    }
    if (ttsModel !== null) {
      formData.append("tts_model", ttsModel);
    }
    if (price !== null) {
      if (price <= 0) {
        toast.error("Price must be a positive number!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
        return;
      } else {
        formData.append("price", price.toString());
      }
    }
    if (maxInputTokens !== null) {
      if (maxInputTokens <= 0) {
        toast.error(
          "Maximum number of input tokens per day must be a positive number!",
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          }
        );
        return;
      } else {
        formData.append("input_tokens", maxInputTokens.toString());
      }
    }
    if (maxOutputTokens !== null) {
      if (maxOutputTokens <= 0) {
        toast.error(
          "Maximum number of output tokens per day must be a positive number!",
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          }
        );
        return;
      } else {
        formData.append("output_tokens", maxOutputTokens.toString());
      }
    }

    if (sttMinutes !== null) {
      if (sttMinutes <= 0) {
        toast.error("Speach to Text minutes must be a positive number!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
        return;
      } else {
        formData.append("stt_minutes", sttMinutes.toString());
      }
    }

    if (ttsCharacters !== null) {
      if (ttsCharacters <= 0) {
        toast.error("Text to Speach characters must be a positive number!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
        return;
      } else {
        formData.append("tts_characters", ttsCharacters.toString());
      }
    }

    try {
      await api.post(`${process.env.REACT_APP_CREATE_PLAN}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setRefresh((prevRefresh: any) => prevRefresh + 1);
      setRefreshData((prevRefresh: any) => prevRefresh + 1);
      closePlan();
      toast.success("Plan has been created successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      if (error instanceof Error) {
        // You can check for specific error properties or just use a default message
        const errorMessage =
          (error as any)?.response?.data?.message ||
          error.message ||
          "An unexpected error occurred";

        toast.error(errorMessage, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      } else {
        // Fallback for when error is not an instance of Error
        toast.error("An error occurred while creating the Plan", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }

      console.log(error);
    }
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-md cursor-default"
      onMouseDown={handlePlanClick}
      role="button"
    >
      <div className="bg-white p-4 rounded shadow-2xl relative">
        <button
          onClick={closePlan}
          className="text-[25px] bg-[#4D30B5] hover:bg-[#1b1436] duration-300 rounded-lg p-1 text-white absolute top-2 right-2"
        >
          <RxCross2 size={25} className="-mt-[1px]" />
        </button>

        <p className="text-[25px] text-center content-center">Create Plan</p>

        <form
          onSubmit={handleUploadSubmit}
          className=" md:w-[750px] w-[350px] max-h-[600px] overflow-y-scroll"
        >
          <div className="p-4 flex flex-col gap-4">
            <div className="grid grid-cols-2 gap-5">
              <div className=" flex flex-col ">
                <label className="pb-2 text-[20px]">Plan Name:</label>
                <input
                  required
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className=" border-[2px] text-black  outline-none p-1 rounded-lg"
                />
              </div>
              <div className="flex flex-col">
                <label className="pb-2 text-[20px]">Price:</label>
                <input
                  required
                  type="number"
                  value={price ?? ""}
                  onChange={(e) =>
                    setPrice(e.target.value ? parseFloat(e.target.value) : null)
                  }
                  className="border-[2px] text-black outline-none p-1 rounded-lg "
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-5">
              <div className="flex flex-col">
                <label className="pb-2 text-[20px]">
                  Maximum Input Tokens Per Day:
                </label>
                <input
                  required
                  type="number"
                  value={maxInputTokens ?? ""}
                  onChange={(e) =>
                    setMaxInputTokens(
                      e.target.value ? parseFloat(e.target.value) : null
                    )
                  }
                  className="border-[2px] text-black outline-none p-1 rounded-lg "
                />
              </div>
              <div className="flex flex-col">
                <label className="pb-2 text-[20px]">
                  Maximum Output Tokens Per Day:
                </label>
                <input
                  required
                  type="number"
                  value={maxOutputTokens ?? ""}
                  onChange={(e) =>
                    setMaxOutputTokens(
                      e.target.value ? parseFloat(e.target.value) : null
                    )
                  }
                  className="border-[2px] text-black outline-none p-1 rounded-lg "
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-5">
              <div className="flex flex-col">
                <label className="pb-2 text-[20px]" htmlFor="english">
                  Model:
                </label>
                <div className=" w-full">
                  <Select
                    value={model}
                    onChange={(e) => setModel(e.target.value)}
                    className="w-full"
                  >
                    <MenuItem key="no" value="gpt-4">
                      gpt-4
                    </MenuItem>
                    <MenuItem key="yes" value="gpt-4o">
                      gpt-4o
                    </MenuItem>
                    <MenuItem key="yes" value="gpt-4o-mini">
                      gpt-4o-mini
                    </MenuItem>
                  </Select>
                </div>
              </div>
            </div>

            <div className="mt-5 border-t-2 pt-5 border-black">
              <div className="mb-2">
                <label className="pb-2 text-[20px]" htmlFor="english">
                  Speach to Text:
                </label>
              </div>
              <div className="grid grid-cols-2 gap-5">
                <div className="flex flex-col">
                  <label className="pb-2 text-[20px]" htmlFor="english">
                    STT Model:
                  </label>
                  <div className=" w-full">
                    <Select
                      value={sttModel}
                      onChange={(e) => setSttModel(e.target.value)}
                      className="w-full"
                    >
                      <MenuItem key="no" value="whisper">
                        Whisper
                      </MenuItem>
                    </Select>
                  </div>
                </div>
                <div className="flex flex-col">
                  <label className="pb-2 text-[20px]">STT Minutes:</label>
                  <input
                    required
                    type="number"
                    value={sttMinutes ?? ""}
                    onChange={(e) =>
                      setSttMinutes(
                        e.target.value ? parseFloat(e.target.value) : null
                      )
                    }
                    className="border-[2px] text-black outline-none p-1 rounded-lg h-full"
                  />
                </div>
              </div>
            </div>

            <div className="mt-5 border-t-2 pt-5 border-black">
              <div className="mb-2">
                <label className="pb-2 text-[20px]" htmlFor="english">
                  Text to Speach:
                </label>
              </div>
              <div className="grid grid-cols-2 gap-5">
                <div className="flex flex-col">
                  <label className="pb-2 text-[20px]" htmlFor="english">
                    TTS Model:
                  </label>
                  <div className=" w-full">
                    <Select
                      value={ttsModel}
                      onChange={(e) => setTtsModel(e.target.value)}
                      className="w-full"
                    >
                      <MenuItem key="no" value="TTS">
                        TTS
                      </MenuItem>
                    </Select>
                  </div>
                </div>
                <div className="flex flex-col">
                  <label className="pb-2 text-[20px]">
                    TTS Maximum Number of Characters:
                  </label>
                  <input
                    required
                    type="number"
                    value={ttsCharacters ?? ""}
                    onChange={(e) =>
                      setTtsCharacters(
                        e.target.value ? parseFloat(e.target.value) : null
                      )
                    }
                    className="border-[2px] text-black outline-none p-1 rounded-lg h-full"
                  />
                </div>
              </div>
            </div>

            <div className="mt-4 flex justify-center">
              <button
                type="submit"
                className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
              >
                Create Plan
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddPlan;

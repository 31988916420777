import axios, { AxiosError } from "axios";
import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import api from "../../../../api";
import { Link, useLocation, useParams } from "react-router-dom";
import { IoChevronBackSharp } from "react-icons/io5";
import { fetchData } from "../../../../GlobalFunctions";
import { ImSpinner } from "react-icons/im";
import DeleteChapter from "../AdminChapters/DeleteChapter";
import { MenuItem, Select } from "@mui/material";
import { Interweave } from "interweave";
import JoditEditor from "jodit-react";
import config from "../Config";
import { BiSolidHide, BiSolidShow } from "react-icons/bi";

let currentIdDatabase = 0;

function EditCourse({ setRefresh }: any) {
  interface CourseData {
    id: string;
    name: string;
    difficulty: string;
    price: number;
    description: string;
    image: string;
  }
  interface ChaptersData {
    id: string;
    title: string;
    description: string;
    image: string;
    allow_open_course: boolean;
  }
  const { course_name } = useParams();
  const [courseData, setCourseData] = useState<CourseData>();
  const [chaptersData, setChaptersData] = useState<ChaptersData[]>([]);
  const [filteredChaptersData, setFilteredChaptersData] = useState<
    ChaptersData[]
  >([]);
  const [name, setName] = useState<string>("");
  const [difficulty, setDifficulty] = useState("1");
  const [description, setDescription] = useState<string>("");
  const [price, setPrice] = useState<number>();
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(true);
  const { state } = useLocation();
  const [courseId, setCourseId] = useState<string>(() => {
    const storedCourseId = window.localStorage.getItem("courseId") || "";
    return (state && state.course_id) || storedCourseId;
  });
  const user_id = localStorage.getItem("user_id") || "default_user";
  const [deleteChapterOpen, setDeleteChapterOpen] = useState(false);
  const [refreshChapters, setRefreshChapters] = useState(0);

  const [imageSrc, setImageSrc] = useState<string>("");
  const editor = useRef(null);

  const openDeleteChapter = (id: number) => {
    currentIdDatabase = id;
    setDeleteChapterOpen(true);
  };

  const closeDeleteChapter = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      setDeleteChapterOpen(false);
    }
  };

  const closeDeleteChapterX = (e: React.MouseEvent<HTMLDivElement>) => {
    setChaptersData([]);
    setDeleteChapterOpen(false);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files && e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result as string);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_COURSES_BY_ID}${courseId}`,
      setCourseData
    );
  }, []);

  useEffect(() => {
    async function fetchData1() {
      try {
        const formData = new FormData();

        formData.append("level_id", "");

        const response = await api.post(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_CHAPTERS_BY_COURSE_ID}${courseId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const data = response.data;
        setChaptersData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData1();
  }, [user_id, refreshChapters]);

  useEffect(() => {
    const filtered = chaptersData
      .filter((chapter) => chapter.title.toLowerCase())
      .sort((a, b) => a.title.localeCompare(b.title));
    setFilteredChaptersData(filtered);
  }, [chaptersData]);

  useEffect(() => {
    if (courseData) {
      setName(courseData?.name);
      setDescription(courseData?.description);
      setPrice(courseData?.price);
      setDifficulty(courseData?.difficulty);
      setLoading(false);
    }
  }, [courseData]);

  useEffect(() => {
    if (price && price < 0) {
      setPrice(0);
    }
  }, [price]);

  const handleUploadSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("difficulty", difficulty);
      formData.append("description", description);
      if (price !== undefined) {
        formData.append("price", price.toString());
      }
      if (file === null) {
        if (courseData?.image) {
          formData.append("logo", courseData.image);
        }
      } else {
        formData.append("logo", file);
      }

      await api.post(
        `${process.env.REACT_APP_EDIT_COURSE}${courseData?.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setRefresh((prevRefresh: any) => prevRefresh + 1);
      window.localStorage.setItem("courseId", courseId);

      window.location.href = "/courses";
      toast.success("Course has been created successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        const errorMessage = axiosError.response?.data || axiosError.message;

        toast.error(
          `An error occurred while creating the Course: ${errorMessage}`,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          }
        );
      } else {
        toast.error("An unexpected error occurred while creating the Course", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }
      console.error("Error creating the Course:", error);
    }
  };

  const handleChangeChapterOpen = async (
    e: any,
    chapter_id: string,
    allow_open_course: boolean
  ) => {
    e.preventDefault();

    try {
      const formData = new FormData();

      formData.append("chapter_id", chapter_id);
      formData.append("allow_open_course", String(allow_open_course));

      await api.post(
        `${process.env.REACT_APP_UPDATE_ALLOW_OPEN_COURSE}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setRefresh((prevRefresh: any) => prevRefresh + 1);
      setRefreshChapters((prevRefresh: any) => prevRefresh + 1);
      window.localStorage.setItem("courseId", courseId);
      toast.success("Allow Open Chapter has been updated!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        const errorMessage = axiosError.response?.data || axiosError.message;

        toast.error(
          `An error occurred while updating the chapter: ${errorMessage}`,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          }
        );
      } else {
        toast.error("An unexpected error occurred while updating the chapter", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }
      console.error("Error updating the chapter:", error);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center mt-10">
        <ImSpinner size={30} className=" animate-spin" />
      </div>
    );
  }

  return (
    <section
      className="overflow-hidden h-screen "
      style={{ maxHeight: "calc(100vh - 50px)" }}
    >
      <div className="flex flex-row items-center w-[210px]  gap-1 mb-2">
        <Link
          className="px-2 hover:-ml-1 hover:pr-3 duration-200 rosunded-lg "
          to={"/courses"}
        >
          <IoChevronBackSharp title="Profile" size={17} />
        </Link>
        <p className="font-bold text-[20px]">Edit Course:</p>
      </div>

      <form
        onSubmit={handleUploadSubmit}
        className="relative h-screen"
        style={{ maxHeight: "calc(100vh - 170px)" }}
      >
        <div
          className="p-4 flex flex-col gap-4 overflow-scroll h-screen "
          style={{ maxHeight: "calc(100vh - 210px)" }}
        >
          <div className=" flex flex-col ">
            <label className="pb-2 text-[20px]">Course Name:</label>
            <input
              required
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className=" border-[2px] text-black  outline-none p-1 rounded-lg"
            />
          </div>
          <div className=" flex flex-col ">
            <label className="pb-2 text-[20px]">Price:</label>
            <input
              required
              type="number"
              value={price ?? ""}
              onChange={(e) =>
                setPrice(
                  e.target.value ? parseFloat(e.target.value) : undefined
                )
              }
              className="border-[2px] text-black outline-none p-1 rounded-lg"
            />
          </div>
          <div className=" flex flex-col mb-10">
            <label className="pb-2 text-[20px]">Description:</label>
            <JoditEditor
              ref={editor}
              config={config}
              value={description}
              onChange={setDescription}
            />
          </div>

          <div className="flex flex-row mt-3">
            <label
              className="text-[20px] content-center mr-4"
              htmlFor="english"
            >
              Difficulty:
            </label>
            <Select
              value={difficulty}
              onChange={(e) => {
                setDifficulty(e.target.value);
              }}
            >
              <MenuItem key="beginner" value="1">
                Beginner
              </MenuItem>
              <MenuItem key="intermediate" value="2">
                Intermediate
              </MenuItem>
              <MenuItem key="advanced" value="3">
                Advanced
              </MenuItem>
            </Select>
          </div>
          <div className=" flex flex-col ">
            <label className="pb-2 text-[20px]">Image:</label>
            <div className="col-span-4  gap-4 justify-center items-center mb-2 ">
              <img
                width={200}
                src={
                  imageSrc === ""
                    ? `data:image/jpg;base64,${courseData?.image}`
                    : imageSrc
                }
                alt=""
                loading="lazy"
              />
            </div>
            <input
              type="file"
              accept=".png, .jpg, .jpeg, .gif"
              onChange={handleFileChange}
            />
          </div>

          <div className=" flex flex-col mt-3 ">
            <div className="flex flex-row justify-between">
              <label className="pb-2 text-[20px]">Lessons:</label>
              <div className="flex">
                <Link
                  state={{ course_id: courseId }}
                  to={`/courses/edit-course/${course_name}/add-chapter`}
                  className={`relative cursor-pointer inline-block px-3 py-2 items-center rounded-lg duration-300 bg-[#4D30B5] hover:bg-[#1b1436] text-white`}
                >
                  Add Session
                </Link>
              </div>
            </div>
            {filteredChaptersData && filteredChaptersData.length > 0 ? (
              <div className=" grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 mt-2">
                {filteredChaptersData?.map((chapter, index) => (
                  <div
                    key={index}
                    className={`relative flex flex-col lg:grid lg:grid-cols-12 mb-10 rounded-lg duration-300 bg-[#EDEAF8] lg:max-h-[420px]`}
                  >
                    <div className="col-span-4 flex flex-row gap-4 justify-center items-center bg-gray-200 ">
                      <img
                        src={`data:image/jpg;base64,${chapter.image}`}
                        alt=""
                        loading="lazy"
                        className="max-h-[420px] w-full h-full"
                      />
                    </div>
                    <div className="col-span-8 p-8 flex flex-col justify-between max-h-[420px]">
                      <div className="flex flex-row justify-between content-center">
                        <p className=" text-[30px] font-bold">
                          {chapter.title}
                        </p>

                        {/* <div className="flex flex-row items-center gap-2">
                          <div className=" text-[18px] font-bold">
                            Allow View Session:
                          </div>
                          <button
                            type="button"
                            onClick={(e: any) => {
                              handleChangeChapterOpen(
                                e,
                                chapter.id,
                                chapter.allow_open_course
                              );
                            }}
                            className="outline-none text-[#135991] duration-300"
                          >
                            {chapter.allow_open_course ? (
                              <BiSolidShow size={22} />
                            ) : (
                              <BiSolidHide size={22} />
                            )}
                          </button>
                        </div> */}
                      </div>
                      <div
                        className=" text-gray-600 overflow-auto"
                        style={{ maxHeight: "calc(100vh - 470px)" }}
                      >
                        <div
                          className="course-description"
                          dangerouslySetInnerHTML={{
                            __html: chapter.description as string,
                          }}
                        />
                      </div>

                      <div className=" flex flex-row gap-2 mt-8 ">
                        <Link
                          state={{
                            chapter_id: chapter.id,
                            course_id: courseId,
                          }}
                          to={`/courses/edit-course/${course_name}/edit-chapter/${chapter.title}`}
                          key={index}
                          className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
                        >
                          Edit Session
                        </Link>
                        <button
                          className=" bg-red-600 hover:bg-red-800 text-white px-5 py-3 rounded duration-300"
                          onClick={(e) => {
                            e.preventDefault();
                            openDeleteChapter(index);
                          }}
                        >
                          Delete Session
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex justify-center">
                <ImSpinner size={30} className=" animate-spin" />
              </div>
            )}
          </div>
        </div>
        <div className=" flex justify-center absolute w-full bottom-0 ">
          <button
            type="submit"
            className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
          >
            Update
          </button>
        </div>
      </form>
      {chaptersData
        ? deleteChapterOpen && (
            <DeleteChapter
              closeDeleteChapterX={closeDeleteChapterX}
              idEdit={filteredChaptersData[currentIdDatabase]?.id}
              nameEdit={filteredChaptersData[currentIdDatabase].title}
              setRefreshChapters={setRefreshChapters}
              closeDeleteChapter={closeDeleteChapter}
            />
          )
        : null}
    </section>
  );
}

export default EditCourse;

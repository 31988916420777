import { io } from "socket.io-client";
import { useState, useEffect, useRef } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { IoChevronBackSharp, IoCloseSharp } from "react-icons/io5";
import { fetchData } from "../../../../GlobalFunctions";
import { ImSpinner } from "react-icons/im";
import { Interweave } from "interweave";
import { CiImageOn, CiStop1 } from "react-icons/ci";
import boxMind from "../../../../../Assets/OkationLogo.png";
import User from "../../../../../Assets/ChatPerson.png";
import api from "../../../../api";
import { toast } from "react-toastify";
import {
  RiDeleteBin5Line,
  RiGuideFill,
  RiMicLine,
  RiMicOffLine,
  RiMoreFill,
} from "react-icons/ri";
import { duration, Switch } from "@mui/material";
import ReactMarkdown from "react-markdown";
import { MdVolumeOff, MdVolumeUp } from "react-icons/md";
import ReactAudioPlayer from "react-audio-player";
import { v4 as uuidv4 } from "uuid";
import { VscSend } from "react-icons/vsc";

let currentInputVar = 0;

function ViewChapter({ setRefresh, userData }: any) {
  interface ChaptersData {
    id: string;
    title: string;
    description: string;
    video_order: number;
    explainer_video: VideoExplainer[];
    live_video: LiveVideo[];
    content: string;
    simulation: string;
    guide: string;
    teacher_guide: string;
    student_guide: string;
    story: string;
    story_video: string;
    hands_on_activity: string;

    include_chat: boolean;
    include_quiz: boolean;
    include_live_video: boolean;
    include_explainer_video: boolean;
    include_simulation: boolean;
    include_story: boolean;
    include_lesson_content: boolean;
    include_teacher_guide: boolean;
    include_hands_on_activity: boolean;
  }

  interface History {
    room_id: number;
    question: string;
    answer: string;
    image: string;
  }

  interface VideoExplainer {
    name: string;
    link: string;
    transcript: string;
    video_id: string;
  }

  interface LiveVideo {
    name: string;
    link: string;
    transcript: string;
    video_id: string;
  }

  interface Message {
    type: "input" | "response" | "clipboard" | "audio";
    message: JSX.Element | string;
    image?: string;
    audio?: string;
    audioId?: string;
  }

  const [tabChange, setTabChange] = useState("studentguide");
  const [activityTabChange, setActivityTabChange] = useState("simulation");

  const [showChat, setShowChat] = useState(false);
  const [inputMessage, setInputMessage] = useState<string>("");
  const [messages, setMessages] = useState<Message[]>([]);
  const [historyData, setHistoryData] = useState<History[]>([]);
  const [pendingImage, setPendingImage] = useState<string | null>(null);
  const { course_name } = useParams();
  const { chapter_name } = useParams();

  const [chapterData, setChapterData] = useState<ChaptersData | null>(null);
  const [explainerVideoIndex, setExplainerVideoIndex] = useState<number>(0);
  const [liveVideoIndex, setLiveVideoIndex] = useState<number>(0);

  const [loading, setLoading] = useState(true);
  const [loadingChat, setLoadingChat] = useState(false);
  const { state } = useLocation();
  const [courseId, setCourseId] = useState<string>(() => {
    const storedCourseId = window.localStorage.getItem("courseId") || "";
    return (state && state.course_id) || storedCourseId;
  });
  const [chapterId, setChapterId] = useState<string>(() => {
    const storedChapterId = window.localStorage.getItem("chapterId") || "";
    return (state && state.chapter_id) || storedChapterId;
  });

  const [gradeId, setGradeId] = useState<string>(() => {
    const storedGradeId = window.localStorage.getItem("gradeId") || "";
    return (state && state.course_grade) || storedGradeId;
  });

  const [materialId, setMaterialId] = useState<string>("");
  const [refreshChat, setRefreshChat] = useState(0);

  const user_id = localStorage.getItem("user_id") || "default_user";

  const [isScrolledUp, setIsScrolledUp] = useState<boolean>(false);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const [roomId, setRoomId] = useState(-1);

  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null
  );
  const [audioChunks, setAudioChunks] = useState<Blob[]>([]);

  const [isGuidedLearningActive, setIsGuidedLearningActive] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const [useTTs, setUseTTs] = useState<boolean>(false);
  const [audioElements, setAudioElements] = useState<{
    [key: string]: HTMLAudioElement;
  }>({});

  const toggleTTS = () => {
    setUseTTs((prevState) => !prevState);
  };

  const overlayRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        overlayRef.current &&
        !overlayRef.current.contains(event.target as Node)
      ) {
        setIsOverlayVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleRecording = async () => {
    if (!isRecording) {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
          });

          const recorder = new MediaRecorder(stream, {
            mimeType: "audio/webm",
          });

          setAudioChunks([]);

          recorder.ondataavailable = (e) => {
            if (e.data.size > 0) {
              setAudioChunks((prev) => [...prev, e.data]);
            } else {
              console.log("No data received from ondataavailable");
            }
          };

          recorder.start(1000);
          setMediaRecorder(recorder);
          setIsRecording(true);
        } catch (error) {
          toast.error("Could not access microphone.", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          });
        }
      } else {
        toast.error("Audio recording is not supported in this browser.", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }
    } else {
      if (mediaRecorder) {
        mediaRecorder.stop();
        setIsRecording(false);

        mediaRecorder.onstop = async () => {
          if (audioChunks.length > 0) {
            const audioBlob = new Blob(audioChunks, { type: "audio/webm" });

            const audioContext = new window.AudioContext();

            try {
              const arrayBuffer = await audioBlob.arrayBuffer();

              const audioBuffer = await audioContext.decodeAudioData(
                arrayBuffer
              );

              const duration = audioBuffer.duration;

              const formData = new FormData();
              formData.append("audio_file", audioBlob);
              formData.append("audio_duration", duration.toString());

              try {
                const response = await api.post(
                  `${process.env.REACT_APP_VOICE}`,
                  formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                );

                const responseData = response.data.transcription;
                setInputMessage(responseData || "");
              } catch (error) {
                toast.error("Failed to process the audio.", {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: false,
                  theme: "dark",
                });
              }
            } catch (error) {
              console.error("Error decoding audio data:", error);
              toast.error("Failed to decode audio data.", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                theme: "dark",
              });
            }
          } else {
            toast.error("No audio was recorded.", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              theme: "dark",
            });
          }
          setAudioChunks([]);
        };
      }
    }
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPendingImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteImage = () => {
    setPendingImage(null);
  };

  const scrollToBottom = () => {
    if (!isScrolledUp) {
      const div = document.getElementById("div-of-scroll");
      if (div !== null) {
        div.scrollTo({
          top: div.scrollHeight,
          behavior: "smooth",
        });
      }
    }
  };

  useEffect(() => {
    scrollToBottom();
    const handleScroll = () => {
      const div = chatContainerRef.current;

      setIsScrolledUp(true);
      if (div && div.scrollTop + div.clientHeight > div.scrollHeight - 5) {
        setIsScrolledUp(false);
      }
    };

    const div = chatContainerRef.current;
    if (div) {
      div.addEventListener("scroll", handleScroll);

      return () => {
        div.removeEventListener("scroll", handleScroll);
      };
    }
  }, [messages, historyData]);

  useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_CHAPTER_BY_ID}${chapterId}`,
      setChapterData
    );
    setLoading(false);
  }, []);

  //delete room
  const handleDelete = async (e: any) => {
    const formData = new FormData();
    formData.append("room_id", String(roomId));

    try {
      await api.post(`${process.env.REACT_APP_DELETE_CHAT}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setRoomId(Math.round(Math.random() * 1e9));
      setMessages([]);
      setHistoryData([]);
      setRefreshChat((prevRefresh: any) => prevRefresh + 1);
      toast.success("Room has been Deleted successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      toast.error("An error occurred while deleting the room", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      console.log(error);
    }
  };

  //get room id
  useEffect(() => {
    async function fetchData1() {
      try {
        const formData = new FormData();
        formData.append("user_id", user_id);
        // formData.append("quiz_id", "");
        // formData.append("question_id", "");
        formData.append("chapter_id", chapterData?.id ?? "");

        formData.append("type", "chapter");

        const response = await api.post(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_ROOM_ID}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const data = response.data;
        if (data !== null && data !== undefined) {
          setRoomId(data);
        } else {
          setRoomId(Math.round(Math.random() * 1e9));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    if (roomId === -1 && showChat) {
      fetchData1();
    }
  }, [showChat, roomId]);

  //open socket on room id
  useEffect(() => {
    if (roomId !== -1 && showChat) {
      const socket = io(`${process.env.REACT_APP_QUESTIONS}`);
      socket.on("connect", () => {
        socket.emit("join", { room_id: roomId });
      });
      socket.on("token", (token: { data: string }) => {
        const responseMessage: Message = {
          type: "response",
          message: token.data,
        };

        var div = document.getElementById(`text-wrap-${currentInputVar}`);

        if (div) {
          div.innerHTML += token.data;
        }

        setMessages((prevMessageList) => [...prevMessageList, responseMessage]);

        if (token.data === "end_stream") {
          refreshChatHistory();
        }
      });

      return () => {
        socket.off("token");
        socket.close();
      };
    }
  }, [showChat, roomId]);

  const refreshChatHistory = async () => {
    try {
      const formData = new FormData();
      formData.append("room_id", String(roomId));

      const response = await api.post(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_HISTORY}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const data = response.data;
      setHistoryData(data);
      setInputMessage("");
      setMessages([]);
      currentInputVar = response.data.length - 1;
    } catch (error) {
      console.error("Error fetching history data:", error);
    }
  };

  //get history data from room id
  useEffect(() => {
    async function fetchData1() {
      try {
        const formData = new FormData();
        // formData.append("user_id", user_id);
        // formData.append("quiz_id", quiz?.quiz_id ?? "");
        // formData.append(
        //   "question_id",
        //   quiz?.questions[currentDatabaseId]?.question_id ?? ""
        // );
        // formData.append("chapter_id", chapterData?.id ?? "");
        // formData.append("type", "quiz");
        formData.append("room_id", String(roomId));

        const response = await api.post(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_HISTORY}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const data = response.data;
        setHistoryData(data);
        setInputMessage("");
        currentInputVar = response.data.length - 1;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    if (roomId !== -1 && showChat) {
      fetchData1();
    }
  }, [showChat, roomId]);

  //send question
  const sendQuestion = () => {
    setIsScrolledUp(false);
    if (inputMessage.trim() === "") return;

    setLoadingChat(true);

    currentInputVar = messages.length;

    const newInputMessage: Message = {
      type: "input",
      message: (
        <>
          <div className="flex justify-end w-full">
            <div className="pb-4 pr-2 flex flex-row gap-2 items-center">
              <div className="h-full">
                <img
                  src={User}
                  alt={"User"}
                  loading="lazy"
                  className=" max-w-[45px] max-h-[28px]"
                />
              </div>
              <div className="w-full">{inputMessage}</div>
            </div>
          </div>
        </>
      ),
    };

    setMessages((prevMessageList) => [...prevMessageList, newInputMessage]);
    setInputMessage("");
    // setSelectedImage(null);

    const formData = new FormData();
    formData.append("message", inputMessage);
    formData.append("user_id", user_id);
    formData.append("chapter_data", chapterData?.student_guide ?? "");
    formData.append("type", "chapter");

    formData.append("room_id", roomId.toString());
    // formData.append("question_id", "");

    if (chapterData) {
      formData.append("chapter_data", chapterData.content);
      formData.append("chapter_id", chapterData.id);
    }
    formData.append("guided_learn", isGuidedLearningActive ? "1" : "0");
    formData.append("use_tts", useTTs.toString());

    api
      .post(`${process.env.REACT_APP_ANSWERS}`, formData)
      .then((response) => {
        setLoadingChat(false);
        const audioId = uuidv4();
        if (response.data.audio) {
          const audioSrc = `data:audio/wav;base64,${response.data.audio}`;
          const audio = new Audio(audioSrc);
          setAudioElements((prev) => ({ ...prev, [audioId]: audio }));
        }
        const audioSrc = response?.data?.audio;
        const responseMessage: Message = {
          type: "response",
          message: response.data.text,
          audioId: audioId,
        };
        const audioMessage: Message = {
          type: "audio",
          message: response?.data?.audio,
        };
        setMessages((prevMessageList) => {
          const newMessages = [...prevMessageList, responseMessage];
          if (audioSrc) {
            newMessages.push(audioMessage);
          }
          return newMessages;
        });

        var imageRotate = document.getElementById(`logo-${currentInputVar}`);

        if (imageRotate === null) {
        } else {
          imageRotate.className = "";
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
        setLoadingChat(false);

        var imageRotate = document.getElementById(`logo-${currentInputVar}`);

        if (imageRotate === null) {
        } else {
          imageRotate.className = "";
        }

        var currentdiv = document.getElementById(
          `text-wrap-${currentInputVar}`
        );
        const res = document.createElement("span");
        res.classList.add("errorbox");
        res.innerHTML = error.response.data.message;
        currentdiv?.appendChild(res);
      });
  };

  const handleStopGeneration = async () => {
    try {
      api.post(`${process.env.REACT_APP_STOP_GENERATION}${roomId}`, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      var imageRotate = document.getElementById(`logo-${currentInputVar}`);

      if (imageRotate === null) {
      } else {
        imageRotate.className = "";
      }
    } catch (error) {}
  };

  if (loading) {
    return (
      <div className="flex justify-center mt-10">
        <ImSpinner size={30} className=" animate-spin" />
      </div>
    );
  }

  return (
    <section
      className="overflow-hidden h-screen"
      style={{ maxHeight: "calc(100vh - 50px)" }}
    >
      <div className="flex flex-col gap-1 mb-2">
        <div className=" flex flex-row items-center gap-1">
          <Link
            state={{ course_id: courseId, course_grade: gradeId }}
            className="px-2 hover:-ml-1 hover:pr-3 duration-200 rosunded-lg"
            to={`/courses/view-course/${course_name}`}
          >
            <IoChevronBackSharp title="Profile" size={17} />
          </Link>
          <p className="font-bold text-[20px]">{chapterData?.title}:</p>
        </div>
        <div>
          <div className=" grid grid-cols-3 md:flex md:flex-row gap-2 w-full py-2">
            {chapterData?.include_lesson_content && (
              <div
                className={`p-2 lg:px-8 text-center duration-300 rounded-md cursor-pointer ${
                  tabChange === "studentguide"
                    ? "bg-[#8462ff] text-white"
                    : "bg-[#4D30B5] text-white hover:bg-[#7257d3]"
                }`}
                onClick={() => {
                  setTabChange("studentguide");
                  setShowChat(false);
                  setMessages([]);
                }}
              >
                Lesson
              </div>
            )}

            {chapterData?.video_order === 1 && (
              <>
                {chapterData?.include_explainer_video && (
                  <div
                    className={`p-2 lg:px-8 text-center duration-300 rounded-md cursor-pointer ${
                      tabChange === "explainer"
                        ? "bg-[#8462ff] text-white"
                        : "bg-[#4D30B5] text-white hover:bg-[#7257d3]"
                    }`}
                    onClick={() => {
                      setTabChange("explainer");
                      setShowChat(false);
                      setMessages([]);
                    }}
                  >
                    Videos
                  </div>
                )}
                {chapterData?.include_live_video && (
                  <div
                    className={`p-2 lg:px-8 text-center duration-300 rounded-md cursor-pointer ${
                      tabChange === "live"
                        ? "bg-[#8462ff] text-white"
                        : "bg-[#4D30B5] text-white hover:bg-[#7257d3]"
                    }`}
                    onClick={() => {
                      setTabChange("live");
                      setShowChat(false);
                      setMessages([]);
                    }}
                  >
                    Videos
                  </div>
                )}
              </>
            )}

            {chapterData?.video_order === 2 && (
              <>
                {chapterData?.include_live_video && (
                  <div
                    className={`p-2 lg:px-8 text-center duration-300 rounded-md cursor-pointer ${
                      tabChange === "live"
                        ? "bg-[#8462ff] text-white"
                        : "bg-[#4D30B5] text-white hover:bg-[#7257d3]"
                    }`}
                    onClick={() => {
                      setTabChange("live");
                      setShowChat(false);
                      setMessages([]);
                    }}
                  >
                    Videos
                  </div>
                )}
                {chapterData?.include_explainer_video && (
                  <div
                    className={`p-2 lg:px-8 text-center duration-300 rounded-md cursor-pointer ${
                      tabChange === "explainer"
                        ? "bg-[#8462ff] text-white"
                        : "bg-[#4D30B5] text-white hover:bg-[#7257d3]"
                    }`}
                    onClick={() => {
                      setTabChange("explainer");
                      setShowChat(false);
                      setMessages([]);
                    }}
                  >
                    Videos
                  </div>
                )}
              </>
            )}

            {chapterData?.include_story && (
              <div
                className={`p-2 lg:px-8 text-center duration-300 rounded-md cursor-pointer ${
                  tabChange === "story"
                    ? "bg-[#8462ff] text-white"
                    : "bg-[#4D30B5] text-white hover:bg-[#7257d3]"
                }`}
                onClick={() => {
                  setTabChange("story");
                  setShowChat(false);
                  setMessages([]);
                }}
              >
                Story
              </div>
            )}

            <div
              className={`p-2 lg:px-8 text-center duration-300 rounded-md cursor-pointer ${
                tabChange === "activity"
                  ? "bg-[#8462ff] text-white"
                  : "bg-[#4D30B5] text-white hover:bg-[#7257d3]"
              }`}
              onClick={() => {
                setTabChange("activity");
                setShowChat(false);
                setMessages([]);
              }}
            >
              Activities
            </div>

            {userData?.roles.some((role: string) =>
              ["teacher", "superadmin", "schooladmin"].includes(role)
            ) &&
              chapterData?.include_teacher_guide && (
                <div
                  className={`p-2 lg:px-8 text-center duration-300 rounded-md cursor-pointer ${
                    tabChange === "teacherguide"
                      ? "bg-[#8462ff] text-white"
                      : "bg-[#4D30B5] text-white hover:bg-[#7257d3]"
                  }`}
                  onClick={() => {
                    setTabChange("teacherguide");
                    setShowChat(false);
                    setMessages([]);
                  }}
                >
                  Teacher Guide
                </div>
              )}
            {chapterData?.include_chat && (
              <div
                className={`p-2 lg:px-8 text-center duration-300 rounded-md cursor-pointer ${
                  showChat
                    ? "bg-[#8462ff] text-white"
                    : "bg-[#4D30B5] text-white hover:bg-[#7257d3]"
                }`}
                onClick={() => {
                  setShowChat(!showChat);
                  setRoomId(-1);
                  setMessages([]);
                }}
              >
                Chat
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="relative h-screen flex">
        <div
          className={` pb-4 flex-col gap-4 overflow-scroll h-screen flex `}
          style={{ maxHeight: "calc(100vh - 210px)", flex: 1 }}
        >
          {tabChange === "explainer" &&
            chapterData?.include_explainer_video && (
              <div className="flex flex-col relative p-5 pt-0">
                <div className=" items-center fixed bg-[#F8FBFD] w-full p-2 pt-5 flex flex-row gap-2">
                  <label className=" items-center text-[20px] font-bold text-[#4D30B5]">
                    Videos:
                  </label>
                  {chapterData?.explainer_video?.map((video, vIndex) => (
                    <div key={vIndex}>
                      <div
                        className={`p-2 px-8 duration-300 rounded-md cursor-pointer ${
                          explainerVideoIndex === vIndex &&
                          tabChange === "explainer"
                            ? "bg-[#7257d3] text-white"
                            : "bg-[#4D30B5] text-white hover:bg-[#7257d3]"
                        }`}
                        onClick={() => {
                          setExplainerVideoIndex(vIndex);
                        }}
                      >
                        {video.name}
                      </div>
                    </div>
                  ))}
                </div>
                <div className=" col-span-2"></div>

                <div className="flex flex-col mt-20">
                  <div className="px-4 py-4 flex justify-center items-center w-full">
                    <iframe
                      title="Video Player"
                      src={
                        chapterData?.explainer_video[explainerVideoIndex].link
                      }
                      allow="fullscreen"
                      allowFullScreen
                      height={600}
                      width={888}
                    />
                  </div>
                  {/* <div className=" flex justify-between py-2">
                    <label className="text-[20px] font-bold text-[#4D30B5]">
                      Transcription:
                    </label>
                  </div>
                  <div>
                    {
                      chapterData?.explainer_video[explainerVideoIndex]
                        .transcript
                    }
                  </div> */}
                </div>
              </div>
            )}

          {tabChange === "live" && chapterData?.include_live_video && (
            <div className="flex flex-col relative p-5 pt-0">
              <div className=" items-center fixed bg-[#F8FBFD] w-full p-2 pt-5 flex flex-row gap-2">
                <label className=" items-center text-[20px] font-bold text-[#4D30B5]">
                  Videos:
                </label>
                {chapterData?.live_video?.map((video, vIndex) => (
                  <div key={vIndex}>
                    <div
                      className={`p-2 px-8 duration-300 rounded-md cursor-pointer ${
                        liveVideoIndex === vIndex && tabChange === "live"
                          ? "bg-[#7257d3] text-white"
                          : "bg-[#4D30B5] text-white hover:bg-[#7257d3]"
                      }`}
                      onClick={() => {
                        setLiveVideoIndex(vIndex);
                      }}
                    >
                      {video.name}
                    </div>
                  </div>
                ))}
              </div>
              <div className=" col-span-2"></div>

              <div className="flex flex-col mt-20">
                <div className="px-4 py-4 flex justify-center items-center w-full">
                  <iframe
                    title="Video Player"
                    src={chapterData?.live_video[liveVideoIndex].link}
                    allow="fullscreen"
                    allowFullScreen
                    height={500}
                    width={888}
                  />
                </div>
                <div className=" flex justify-between py-2">
                  <label className="text-[20px] font-bold text-[#4D30B5]">
                    Transcription:
                  </label>
                </div>
                <div>{chapterData?.live_video[liveVideoIndex].transcript}</div>
              </div>
            </div>
          )}

          {tabChange === "activity" && (
            <div className="flex flex-col relative p-5 pt-0">
              <div className=" items-center fixed bg-[#F8FBFD] w-full p-2 pt-5 flex flex-row gap-2">
                {chapterData?.include_simulation && (
                  <div
                    className={`p-2 px-8 duration-300 rounded-md cursor-pointer ${
                      activityTabChange === "simulation"
                        ? "bg-[#7257d3] text-white"
                        : "bg-[#4D30B5] text-white hover:bg-[#7257d3]"
                    }`}
                    onClick={() => {
                      setActivityTabChange("simulation");
                    }}
                  >
                    Simulation
                  </div>
                )}
                {chapterData?.include_hands_on_activity && (
                  <div
                    className={`p-2 px-8 duration-300 rounded-md cursor-pointer ${
                      activityTabChange === "handsonactivity"
                        ? "bg-[#7257d3] text-white"
                        : "bg-[#4D30B5] text-white hover:bg-[#7257d3]"
                    }`}
                    onClick={() => {
                      setActivityTabChange("handsonactivity");
                    }}
                  >
                    Hands-on Activity
                  </div>
                )}
                {chapterData?.include_quiz && (
                  <div className=" flex flex-row justify-between">
                    <Link
                      className=" bg-[#4D30B5] text-white py-2 px-4 hover:bg-[#7257d3] duration-300 rounded-md"
                      to={`/courses/view-course/${course_name}/view-chapter/${chapter_name}/quiz`}
                      state={{ course_id: courseId, chapter_id: chapterId }}
                    >
                      Quiz
                    </Link>
                  </div>
                )}
              </div>
              <div className=" col-span-2"></div>

              {activityTabChange === "simulation" &&
                chapterData?.include_simulation && (
                  <div className="flex flex-col mt-20 p-5">
                    <div className="px-4 py-4 flex justify-center items-center w-full">
                      <iframe
                        title="Video Player"
                        src={chapterData?.simulation}
                        allow="fullscreen"
                        allowFullScreen
                        height={500}
                        width={888}
                      />
                    </div>
                    <div className=" flex justify-between py-2">
                      <label className="text-[20px] font-bold text-[#4D30B5]">
                        Guide:
                      </label>
                    </div>
                    <div className=" whitespace-break-spaces">
                      {chapterData?.guide}
                    </div>
                  </div>
                )}

              {activityTabChange === "handsonactivity" &&
                chapterData?.include_hands_on_activity && (
                  <div className="flex flex-col mt-20 p-5">
                    <div className={`${showChat && "px-2"}`}>
                      <div
                        className="course-description"
                        dangerouslySetInnerHTML={{
                          __html: chapterData?.hands_on_activity as string,
                        }}
                      />
                    </div>
                  </div>
                )}
            </div>
          )}

          {tabChange === "studentguide" && (
            <div className=" p-5">
              <div className={`${showChat && "px-2"}`}>
                <div
                  className="course-description"
                  dangerouslySetInnerHTML={{
                    __html: chapterData?.student_guide as string,
                  }}
                />
              </div>
              {/* <div className=" pb-4">
                {chapterData?.include_quiz === "yes" && (
                  <div className=" flex flex-row justify-between">
                    <Link
                      className=" bg-[#4D30B5] text-white py-2 px-4 hover:bg-[#7257d3] duration-300 rounded-md"
                      to={`/courses/view-course/${course_name}/view-chapter/${chapter_name}/quiz`}
                      state={{ course_id: courseId, chapter_id: chapterId }}
                    >
                      Take Quiz
                    </Link>
                  </div>
                )}
              </div> */}
            </div>
          )}

          {tabChange === "story" && (
            <>
              {chapterData?.story_video !== "" && (
                <div className="px-4 py-4 flex justify-center items-center w-full">
                  <iframe
                    title="Video Player"
                    src={chapterData?.story_video}
                    allow="fullscreen"
                    allowFullScreen
                    height={500}
                    width={888}
                  />
                </div>
              )}
              <div className=" flex justify-between py-2">
                <label className="text-[20px] font-bold text-[#4D30B5]">
                  Transcription:
                </label>
              </div>
              <div className={`${showChat && "px-2"}`}>
                <div
                  className="course-description"
                  dangerouslySetInnerHTML={{
                    __html: chapterData?.story as string,
                  }}
                />
              </div>
            </>
          )}

          {tabChange === "teacherguide" && (
            <div className="p-5">
              <div
                className="course-description"
                dangerouslySetInnerHTML={{
                  __html: chapterData?.teacher_guide as string,
                }}
              />
            </div>
          )}
        </div>
        {showChat && (
          <div className={`px-4 flex flex-col max-sm:w-full w-1/2 border-l`}>
            <div className="flex flex-row justify-between items-center relative">
              <h2 className="text-xl font-bold ">Answer Guide</h2>
              <button onClick={() => setIsOverlayVisible((prev) => !prev)}>
                <RiMoreFill
                  size={25}
                  className="text-gray-600 hover:text-gray-800 duration-300"
                />
              </button>
            </div>
            {isOverlayVisible && (
              <div
                ref={overlayRef}
                className="absolute flex flex-col items-center gap-3 right-0 mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg z-10"
              >
                <div className=" flex justify-center items-center w-full bg-black rounded-t-lg">
                  <p className=" text-white">More Options</p>
                </div>
                <button
                  onClick={(e: any) => {
                    handleDelete(e);
                    setIsOverlayVisible(false);
                  }}
                  className="flex items-center justify-center px-2 py-2 w-full text-red-600 hover:bg-red-100 duration-300"
                >
                  <RiDeleteBin5Line size={25} className="mr-2" />
                  Delete Chat
                </button>

                <div className="flex items-center justify-center px-2 py-2">
                  <Switch
                    checked={isGuidedLearningActive}
                    onChange={(event) =>
                      setIsGuidedLearningActive(event.target.checked)
                    }
                    color="primary"
                  />
                  <span className="ml-2">Guided Learn</span>
                </div>
                {userData?.tts_model === true && (
                  <button
                    onClick={toggleTTS}
                    className="p-2 rounded hover:bg-gray-300 duration-300 w-full"
                    title={
                      useTTs
                        ? "Turn off text-to-speech"
                        : "Turn on text-to-speech"
                    }
                  >
                    {useTTs ? (
                      <div className=" flex flex-row gap-2 items-center justify-center">
                        <p>Turn OFF TTS</p>
                        <MdVolumeUp size={24} className="text-blue-500" />
                      </div>
                    ) : (
                      <div className=" flex flex-row gap-2 items-center justify-center">
                        <p>Turn ON TTS</p>
                        <MdVolumeOff size={24} className="text-gray-500" />
                      </div>
                    )}
                  </button>
                )}
              </div>
            )}
            <div
              className="overflow-auto mb-4"
              style={{
                height:
                  window.innerWidth <= 768
                    ? "calc(100vh - 390px)"
                    : "calc(100vh - 340px)",
              }}
              ref={chatContainerRef}
              id="div-of-scroll"
            >
              {historyData?.map((data, index) => (
                <div key={index}>
                  <div className="flex justify-end w-full">
                    <div className="pb-4 pr-2 flex flex-row gap-2 items-center">
                      <div className="h-full">
                        <img
                          src={User}
                          loading="lazy"
                          width={45}
                          height={35}
                          alt="User"
                        />
                      </div>
                      <div className=" flex flex-col gap-2 mt-1 w-full">
                        <p>{data?.question}</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center mb-5">
                    <div className={`flex flex-row gap-2 mt-2 w-full`}>
                      <div className=" h-full">
                        <img
                          src={boxMind}
                          alt="boxMind"
                          loading="lazy"
                          width={35}
                          height={35}
                          className=""
                        />
                      </div>
                      <div className=" w-full">
                        <ReactMarkdown>{data.answer}</ReactMarkdown>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <>
                {messages?.map((item, i) =>
                  item.type === "input" ? (
                    <div key={i}>
                      <div className=" flex justify-end">{item?.message}</div>
                      <div>
                        <div className=" flex flex-row gap-2 mt-2">
                          <div>
                            <img
                              src={boxMind}
                              alt="boxMind"
                              width={35}
                              loading="lazy"
                              height={35}
                              className=" animate-spin"
                              id={`logo-${i}`}
                            />
                          </div>
                          <div className=" w-full whitespace-break-spaces">
                            {useTTs === true ? (
                              <div id={`text-wrap-${i}`}>
                                {messages[i + 1] &&
                                  messages[i + 1].type === "response" && (
                                    <div>
                                      {messages[i + 1]?.audioId &&
                                        audioElements[
                                          messages[i + 1]?.audioId ?? ""
                                        ] && (
                                          <div>
                                            <ReactAudioPlayer
                                              src={
                                                audioElements[
                                                  messages[i + 1]?.audioId ?? ""
                                                ].src
                                              }
                                              autoPlay={true}
                                              controls
                                            />
                                          </div>
                                        )}
                                      <ReactMarkdown>
                                        {messages[i + 1].message as string}
                                      </ReactMarkdown>
                                    </div>
                                  )}
                              </div>
                            ) : (
                              <p id={`text-wrap-${i}`}></p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null
                )}
              </>
            </div>
            <div className="flex flex-row items-center bg-white border rounded-md outline-none relative">
              {/* <div>
                <label htmlFor="imageInput" className="cursor-pointer">
                  <input
                    type="file"
                    id="imageInput"
                    accept=".png, .jpg, .jpeg"
                    className="hidden"
                    multiple={false}
                    onChange={handleImageUpload}
                  />
                  <div className="ml-2 rounded-md cursor-pointer">
                    <CiImageOn size={20} />
                  </div>
                </label>
              </div> */}
              {userData.sst_model === true && (
                <button
                  onClick={handleRecording}
                  className="p-2 rounded bg-gray-200 hover:bg-gray-300 duration-300"
                >
                  {isRecording ? (
                    <CiStop1 size={24} className="text-red-500" />
                  ) : (
                    <RiMicLine size={24} className="text-blue-500" />
                  )}
                </button>
              )}
              <input
                type="text"
                value={inputMessage}
                className="rounded p-2 outline-none w-full"
                placeholder="Type your message..."
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !loadingChat && !e.shiftKey) {
                    sendQuestion();
                    e.preventDefault();
                  }
                }}
                onChange={(e) => setInputMessage(e.target.value)}
              />

              <button
                type="button"
                onClick={() => sendQuestion()}
                disabled={loadingChat || inputMessage.length === 0}
                className={`${
                  inputMessage.length !== 0
                    ? "bg-[#135991] text-white p-2 rounded h-full"
                    : "p-2 h-full"
                } ${loadingChat ? " bg-[#163752] rounded" : ""}`}
              >
                <VscSend size={20} />
              </button>

              {pendingImage && (
                <div className=" bottom-12 rounded-lg w-full absolute bg-[#F8FBFD]">
                  <div className=" relative">
                    <img
                      src={pendingImage}
                      alt="Pending Upload"
                      className="w-[100px] h-[100px] rounded-md object-cover"
                    />
                    <button
                      onClick={handleDeleteImage}
                      className=" rounded-md cursor-pointer absolute top-1 left-1 bg-red-600 text-white p-1"
                    >
                      <IoCloseSharp />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default ViewChapter;

import { useEffect, useRef, useState, DragEvent, ChangeEvent } from "react";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import api from "../../../../api";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { fetchData } from "../../../../GlobalFunctions";
import { BsUpload } from "react-icons/bs";
import { FiUpload } from "react-icons/fi";
import { SlCloudUpload } from "react-icons/sl";

function AddList({
  handleListClick,
  closeList,
  setRefresh,
  setRefreshUserData,
  userData,
  refresh,
}: any) {
  interface RolesData {
    role_name: string;
    role_id: string;
  }

  interface SchoolsData {
    name: string;
    id: string;
  }
  const [userID, setUserID] = useState<any | null>(
    localStorage.getItem("user_id")
  );
  const [files, setFiles] = useState<File[]>([]);
  const [fileContents, setFileContents] = useState<{ name: string }[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const allowedFileExtensions = [".xlsx", ".xls"];
  const MAX_FILE_SIZE_MB = 10;
  const [schoolsData, setSchoolsData] = useState<SchoolsData[]>([]);
  const [selectedSchool, setSelectedSchool] = useState("");
  const [selectedRole, setSelectedRole] = useState<string>("");

  useEffect(() => {
    if (userData.roles.includes("superadmin")) {
      fetchData(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_ALL_SCHOOLS}`,
        setSchoolsData
      );
    }
  }, [userID]);

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      const newFiles = Array.from(selectedFiles).filter(
        (file) =>
          allowedFileExtensions.some((ext) =>
            file.name.toLowerCase().endsWith(ext)
          ) && file.size <= MAX_FILE_SIZE_MB * 1024 * 1024
      );

      const uniqueNewFiles = newFiles.filter(
        (file) =>
          !fileContents.some((existingFile) => existingFile.name === file.name)
      );

      setFiles([...files, ...uniqueNewFiles]);
      setFileContents([
        ...fileContents,
        ...uniqueNewFiles.map((file) => ({ name: file.name })),
      ]);
    }
  };

  const handleFileDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles) {
      const newFiles = Array.from(droppedFiles).filter(
        (file) =>
          allowedFileExtensions.some((ext) =>
            file.name.toLowerCase().endsWith(ext)
          ) && file.size <= MAX_FILE_SIZE_MB * 1024 * 1024
      );

      const uniqueNewFiles = newFiles.filter(
        (file) =>
          !fileContents.some((existingFile) => existingFile.name === file.name)
      );

      setFiles([...files, ...uniqueNewFiles]);
      setFileContents([
        ...fileContents,
        ...uniqueNewFiles.map((file) => ({ name: file.name })),
      ]);
    }
  };

  const handleDeleteFile = (index: number) => {
    const updatedFiles = [...files];
    const updatedFileContents = [...fileContents];
    updatedFiles.splice(index, 1);
    updatedFileContents.splice(index, 1);
    setFiles(updatedFiles);
    setFileContents(updatedFileContents);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleSchoolChange = (event: SelectChangeEvent) => {
    setSelectedSchool(event.target.value);
  };

  const handleRoleChange = (event: SelectChangeEvent) => {
    setSelectedRole(event.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (files.length > 0) {
      setIsLoading(true);
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("file", file);
      });
      formData.append("role_name", selectedRole);
      formData.append(
        "school_id",
        userData.roles.includes("superadmin")
          ? selectedSchool
          : userData?.school_id
      );

      try {
        const response = await api.post(
          `${process.env.REACT_APP_ADD_EXCEL}`,
          formData,
          {
            responseType: "blob",
          }
        );

        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "users_report.xlsx"); // Set the default download file name
        document.body.appendChild(link);
        link.click(); // Trigger the download
        document.body.removeChild(link); // Clean up the link element

        setRefresh((prevRefresh: any) => prevRefresh + 1);
        setRefreshUserData((prevRefresh: any) => prevRefresh + 1);
        toast.success(
          "Files have been uploaded successfully and report file has been downloaded!",
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          }
        );

        setFileContents([]);
        setFiles([]);
      } catch (error: any) {
        let errorMessage = "An error occurred while uploading files.";
        if (error.response && error.response.data) {
          try {
            const blobText = await error.response.data.text();
            const errorData = JSON.parse(blobText);
            errorMessage = errorData.message || errorData.error || errorMessage;
          } catch (parseError) {
            console.error("Error parsing error response:", parseError);
          }
        }

        toast.error(`Error: ${errorMessage}`, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
        console.error("Error uploading files:", error);
        setFileContents([]);
        setFiles([]);
        setIsLoading(false);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      } finally {
        setIsLoading(false);
        setFileContents([]);
        setFiles([]);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      }
    }
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-md cursor-default"
      onMouseDown={handleListClick}
      role="button"
    >
      <div className="relative bg-white p-4 rounded shadow-2xl h-[90%] overflow-scroll">
        <button
          onClick={closeList}
          className="text-[25px] bg-[#4D30B5] hover:bg-[#1b1436] p-1 duration-300 rounded-lg text-white absolute top-2 right-2"
        >
          <RxCross2 size={25} className="-mt-[1px]" />
        </button>

        <p className="text-[25px] text-center content-center">
          Add Users Excel List
        </p>

        <div className=" md:w-[750px] w-[350px]">
          <div className="flex flex-row gap-2 mb-5 items-center">
            <button
              onClick={(e: any) => handleSubmit(e)}
              disabled={
                isLoading ||
                files.length === 0 ||
                (userData.roles.includes("superadmin") && !selectedSchool) ||
                !selectedRole
              }
              className={`px-4 ${
                isLoading ||
                files.length === 0 ||
                (userData.roles.includes("superadmin") && !selectedSchool) ||
                !selectedRole
                  ? "bg-[#20144C]"
                  : "bg-[#413183]"
              } p-2 rounded-lg flex flex-row items-center gap-2 text-white hover:bg-[#20144C] duration-300`}
            >
              <BsUpload />
              <p>Upload Excel File</p>
            </button>
          </div>

          {userData.roles.includes("superadmin") && (
            <div className="mb-4">
              <Select
                value={selectedSchool}
                onChange={handleSchoolChange}
                displayEmpty
                fullWidth
                className="mb-2"
              >
                <MenuItem value="" disabled>
                  Select a school
                </MenuItem>
                {schoolsData.map((school) => (
                  <MenuItem key={school.id} value={school.id}>
                    {school.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}

          <div className="mb-4">
            <Select
              value={selectedRole}
              onChange={handleRoleChange}
              displayEmpty
              fullWidth
              className="mb-2"
            >
              <MenuItem value="" disabled>
                Select a role
              </MenuItem>
              <MenuItem value="student">Student</MenuItem>
              <MenuItem value="teacher">Teacher</MenuItem>
            </Select>
          </div>

          <div className="flex flex-col items-center space-y-4">
            <div
              className="border-dashed border-2 border-gray-400 p-4 rounded-lg w-full h-[400px] flex flex-col items-center justify-center cursor-pointer"
              onDragOver={(e) => e.preventDefault()}
              onDrop={handleFileDrop}
              onClick={() => fileInputRef.current?.click()}
              role="button"
            >
              {fileContents.length > 0 ? (
                <div className=" max-h-[200px] overflow-y-scroll">
                  {fileContents.map((file, index) => (
                    <div key={index}>{file.name}</div>
                  ))}
                </div>
              ) : (
                <div className=" flex flex-col items-center gap-4">
                  <FiUpload size={50} color="#20144C" />
                  <p className="text-sm">
                    Click or drag and drop your files here
                  </p>
                </div>
              )}
              <button
                disabled={isLoading}
                className={`bg-[#413183] mt-10 text-white px-4 py-2 rounded-md hover:bg-[#20144C] duration-300 ${
                  isLoading && "opacity-50 cursor-not-allowed"
                }`}
              >
                {isLoading ? (
                  <div className=" flex flex-row gap-2 items-center">
                    <p>Uploading</p>
                    <SlCloudUpload size={25} className=" animate-pulse" />
                  </div>
                ) : (
                  "Select Files"
                )}
              </button>
              <div className=" py-2 flex flex-col items-center gap-2">
                <p>Supported formats</p>
                <div className=" flex flex-row gap-2 text-white">
                  <span className=" bg-[#20144C] px-2 p-1 rounded-lg">
                    XLSX
                  </span>
                </div>
                <p>Document must be less than 10 MB</p>
              </div>
            </div>
          </div>

          <input
            type="file"
            multiple
            accept=".xlsx, .xls"
            ref={fileInputRef}
            className="hidden"
            onChange={handleFileSelect}
          />
          <div>
            <div>
              {fileContents.map((file, index) => (
                <div key={index} className=" flex flex-row gap-4 items-center">
                  <p>{file.name}</p>
                  <button
                    className="text-red-500 hover:text-red-700"
                    onClick={() => handleDeleteFile(index)}
                  >
                    Delete
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddList;

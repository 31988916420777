import * as React from "react";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { fetchData } from "../../../../GlobalFunctions";

type TickParamsSelectorProps = {
  tickPlacement: "end" | "start" | "middle" | "extremities";
  tickLabelPlacement: "tick" | "middle";
  setTickPlacement: React.Dispatch<
    React.SetStateAction<"end" | "start" | "middle" | "extremities">
  >;
  setTickLabelPlacement: React.Dispatch<
    React.SetStateAction<"tick" | "middle">
  >;
};

function TickParamsSelector({
  tickPlacement,
  tickLabelPlacement,
  setTickPlacement,
  setTickLabelPlacement,
}: TickParamsSelectorProps) {
  return null;
}

function valueFormatter(value: number | null) {
  return `${value} Stars`;
}

function gradient(startColor: string, endColor: string): string {
  return `linear-gradient(to right, ${startColor}, ${endColor})`;
}

const chartSetting = {
  series: [
    {
      dataKey: "average_rating",
      label: "Average Rating",
      valueFormatter,
      color: "#0EAF49",
    },
  ],
  height: 500,
  margin: { bottom: 120 }, // Add space for rotated labels
  sx: {
    [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
      transform: "translateX(-10px)",
    },
    [`& .${axisClasses.directionX} .${axisClasses.tickLabel}`]: {
      fontSize: "5px", // Adjust the font size to make the labels more compact
      transform: "rotate(90deg) translateX(10px) translateY(-13px)",
      textAnchor: "start",
      whiteSpace: "normal",
      overflow: "visible",
    },
  },
};

export default function TickPlacementBars() {
  const [tickPlacement, setTickPlacement] = React.useState<
    "start" | "end" | "middle" | "extremities"
  >("middle");
  const [tickLabelPlacement, setTickLabelPlacement] = React.useState<
    "middle" | "tick"
  >("middle");

  interface RatingsData {
    average_rating: number;
    course_id: string;
    course_name: string;
  }

  const [ratingsData, setRatingsData] = React.useState<RatingsData[]>([]);

  React.useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_ADMIN_DASHBOARD_RATINGS}`,
      setRatingsData
    );
    // setRatingsData([
    //   { average_rating: 4.3, course_id: "1", course_name: "Math 101" },
    //   { average_rating: 3.8, course_id: "2", course_name: "Science 101" },
    //   { average_rating: 4.9, course_id: "3", course_name: "History 101" },
    //   { average_rating: 2.6, course_id: "4", course_name: "Literature 101" },
    //   { average_rating: 3.1, course_id: "5", course_name: "Philosophy 101" },
    //   { average_rating: 4.0, course_id: "6", course_name: "Physics 101" },
    //   { average_rating: 4.5, course_id: "7", course_name: "Chemistry 101" },
    //   { average_rating: 2.9, course_id: "8", course_name: "Biology 101" },
    //   { average_rating: 3.3, course_id: "9", course_name: "Art 101" },
    //   { average_rating: 5.0, course_id: "10", course_name: "Music 101" },
    //   { average_rating: 3.7, course_id: "11", course_name: "Psychology 101" },
    //   { average_rating: 4.8, course_id: "12", course_name: "Economics 101" },
    //   { average_rating: 3.0, course_id: "13", course_name: "Sociology 101" },
    //   {
    //     average_rating: 2.8,
    //     course_id: "14",
    //     course_name: "Political Science 101",
    //   },
    //   { average_rating: 4.2, course_id: "15", course_name: "Engineering 101" },
    //   {
    //     average_rating: 3.4,
    //     course_id: "16",
    //     course_name: "Computer Science 101",
    //   },
    //   { average_rating: 4.7, course_id: "17", course_name: "Geography 101" },
    //   { average_rating: 3.6, course_id: "18", course_name: "Astronomy 101" },
    //   { average_rating: 3.9, course_id: "19", course_name: "Law 101" },
    //   { average_rating: 2.5, course_id: "20", course_name: "Anthropology 101" },
    //   { average_rating: 1.0, course_id: "21", course_name: "Art History 101" },
    //   { average_rating: 0.5, course_id: "22", course_name: "Astrology 101" },
    //   {
    //     average_rating: 4.2,
    //     course_id: "23",
    //     course_name: "Machine Learning 101",
    //   },
    //   {
    //     average_rating: 0.7,
    //     course_id: "24",
    //     course_name: "Philosophy of Science 101",
    //   },
    //   { average_rating: 2.1, course_id: "25", course_name: "Econometrics 101" },
    //   {
    //     average_rating: 4.6,
    //     course_id: "26",
    //     course_name: "Quantum Physics 101",
    //   },
    //   { average_rating: 2.3, course_id: "27", course_name: "Logic 101" },
    //   {
    //     average_rating: 1.9,
    //     course_id: "28",
    //     course_name: "Environmental Science 101",
    //   },
    //   {
    //     average_rating: 3.5,
    //     course_id: "29",
    //     course_name: "Engineering Design 101",
    //   },
    //   {
    //     average_rating: 4.1,
    //     course_id: "30",
    //     course_name: "Cognitive Science 101",
    //   },
    // ]);
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <TickParamsSelector
        tickPlacement={tickPlacement}
        tickLabelPlacement={tickLabelPlacement}
        setTickPlacement={setTickPlacement}
        setTickLabelPlacement={setTickLabelPlacement}
      />
      <BarChart
        dataset={ratingsData.map((data) => ({
          course_name: data.course_name,
          average_rating: data.average_rating,
        }))}
        xAxis={[
          {
            scaleType: "band",
            dataKey: "course_name",
            tickPlacement,
            tickLabelPlacement,
            tickLabelStyle: {
              angle: 90,
              textAnchor: "start",
              fontSize: 12,
            },
          },
        ]}
        yAxis={[
          {
            label: "Rating (5)",
            colorMap: {
              type: "continuous",
              min: 0,
              max: 5,
              color: (t: number) => {
                console.log("Average Rating (t):", t);
                if (t <= 0.2) return "#EB2128";
                if (t <= 0.4) return "#FB6837";
                if (t <= 0.6) return "#FFAE38";
                if (t <= 0.8) return "#87D44B";
                return "#26B53A";
              },
            },
          },
        ]}
        {...chartSetting}
      />
    </div>
  );
}

import { useState, useEffect } from "react";
import { BsChatLeft, BsFiletypePdf } from "react-icons/bs";
import { PiSpinnerGapLight } from "react-icons/pi";
import { toast } from "react-toastify";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlineEdit, AiOutlineCheck } from "react-icons/ai";
import { RxCross1 } from "react-icons/rx";
import DeleteHistory from "./DeleteHistory";
import api from "../../../../api";

interface History {
  chat_date: Date;
  chat_title: string;
  user_id: number;
  room_id: number;
}

function ChatHistory({
  setSelectedRoomId,
  selectedRoomId,
  setMessageList,
  loading,
  toggleSidebar,
  setHistoryMainData,
  refreshRooms,
  setFile,
  setUseTTs,
}: any) {
  const [historyData, setHistoryData] = useState<History[] | null>(null);
  const [editActive, setEditActive] = useState<boolean>(false);

  const client_id = localStorage.getItem("user_id");

  const [openDelete, setOpenDelete] = useState(false);
  const [refresh, setRefresh] = useState(0);

  const openDeleteButton = () => {
    setOpenDelete(true);
  };

  const closeDeleteButton = () => {
    setOpenDelete(false);
  };

  const handleButtonClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeDeleteButton();
    }
  };

  const [title, setTitle] = useState("");

  const isSmallScreen = window.innerWidth <= 768;

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_GET_ALL_ROOMS}${client_id}`;

    api
      .get(apiUrl)
      .then((response) => {
        const data = response.data;
        const sortedData = data.sort((a: any, b: any) => {
          const dateA = new Date(a.chat_date);
          const dateB = new Date(b.chat_date);
          return dateB.getTime() - dateA.getTime();
        });
        setHistoryData(sortedData);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [loading, refresh, refreshRooms]);

  // useEffect(() => {
  //   fetchData(
  //     `${process.env.REACT_APP_GET_ALL_ROOMS}${client_id}`,
  //     setHistoryData
  //   );
  // }, [refresh, loading, refreshRooms]);

  if (!historyData) {
    return (
      <div className=" text-white flex justify-center">
        <PiSpinnerGapLight size={30} className=" animate-spin" />
      </div>
    );
  }

  const handleEditRoom = async () => {
    const formData = new FormData();

    formData.append("title", title);
    formData.append("room_id", selectedRoomId);

    try {
      await api.post(`${process.env.REACT_APP_EDIT_TITLE}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setRefresh((prevRefresh: any) => prevRefresh + 1);
      setEditActive(false);
      toast.success("Title has been updated successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      toast.error("An error occurred while updating the title", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      console.log(error);
    }
  };

  const openEdit = () => {
    setEditActive(true);
  };

  const closeEdit = (defaultTitle: string, num: number) => {
    if (num !== selectedRoomId) {
      setEditActive(false);
      setTitle(defaultTitle);
    }
  };

  const changeTitle = (text: string, num: number) => {
    if (num !== selectedRoomId) {
      setTitle(text);
    }
  };

  const closeEditActive = (defaultTitle: string) => {
    setEditActive(false);
    setTitle(defaultTitle);
  };

  return (
    <section>
      <div className="flex flex-col gap-2">
        {historyData.map((history) => (
          <div
            className={`cursor-pointer rounded-lg p-2 text-base hover:text-white duration-300 ${
              selectedRoomId === history?.room_id
                ? "text-white"
                : "text-[#a9aaac] "
            }`}
            key={history.room_id}
          >
            <div
              onClick={() => {
                if (history.room_id === selectedRoomId) {
                } else {
                  setSelectedRoomId(history?.room_id);
                  setMessageList([]);
                  changeTitle(history?.chat_title, history?.room_id);
                  closeEdit(history?.chat_title, history?.room_id);
                  setFile(null);
                  if (isSmallScreen) {
                    toggleSidebar();
                  }
                }
              }}
              role="button"
              className=" relative flex flex-row items-center gap-3"
            >
              <div>
                <BsChatLeft size={20} />
              </div>
              {history?.room_id === selectedRoomId ? (
                editActive === false ? (
                  <span className="text-[13px] pb-1 truncate w-[62%]">
                    {history.chat_title}
                  </span>
                ) : (
                  <input
                    autoFocus
                    className=" bg-[#000024] w-[65%] border outline-none rounded-sm text-[13px] pb-1"
                    required
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                )
              ) : (
                <span className="text-[13px] pb-1 truncate w-[65%]">
                  {history.chat_title}
                </span>
              )}

              {selectedRoomId === history.room_id && (
                <>
                  {editActive === false && (
                    <div className="absolute right-0 gap-1 flex flex-row">
                      <button
                        className=" hover:text-green-400 duration-300"
                        onClick={openEdit}
                      >
                        <AiOutlineEdit />
                      </button>
                      <button
                        className=" hover:text-red-400 duration-300"
                        onClick={() => openDeleteButton()}
                      >
                        <RiDeleteBinLine />
                      </button>
                    </div>
                  )}
                  {editActive === true && (
                    <div className="absolute right-0 gap-1 flex flex-row">
                      <button
                        className=" hover:text-green-400 duration-300"
                        onClick={handleEditRoom}
                      >
                        <AiOutlineCheck />
                      </button>
                      <button
                        className=" hover:text-red-400 duration-300"
                        onClick={() => closeEditActive(history?.chat_title)}
                      >
                        <RxCross1 />
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        ))}
      </div>
      {openDelete && (
        <DeleteHistory
          handleButtonClick={handleButtonClick}
          selectedRoomId={selectedRoomId}
          title={title}
          setRefresh={setRefresh}
          setHistoryMainData={setHistoryMainData}
          setMessageList={setMessageList}
          setOpenDelete={setOpenDelete}
          setSelectedRoomId={setSelectedRoomId}
          setFile={setFile}
          setUseTTs={setUseTTs}
        />
      )}
    </section>
  );
}

export default ChatHistory;
